import React, { useEffect } from "react";
import LockImg from "assets/images/LockImg.png";
import { type GetOtpForm } from "utils/types/user.type";
import { type SubmitHandler, useForm } from "react-hook-form";
import { useGetOtp } from "utils/api/auth.api";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Input } from "components/input";
// import WalletIcon from "assets/images/WalletIcon.svg";
import backIcon from "assets/icons/back.svg";
import Logo from "../../assets/icons/logo.svg";
export default function ForgotPassword() {
  const navigate = useNavigate();

  const { mutate: verifyEmail, isSuccess, isLoading } = useGetOtp();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<GetOtpForm>();

  const onSubmit: SubmitHandler<GetOtpForm> = (data) =>
    verifyEmail(data?.email);

  useEffect(() => {
    if (isSuccess) {
      navigate("/verify-email", {
        state: { email: getValues("email"), isForgot: true },
      });
      toast.success("Email sent successfully!");
    }
  }, [isSuccess]);

  return (
    <section className="login-section  h-screen overflow-hidden">
      <div className="flex flex-col h-full ">
        <header
          style={{
            paddingTop: "30px",

            paddingLeft: "30px",

            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          {" "}
          <img style={{ height: "50px" }} src={Logo} alt="" />{" "}
          {/* <span className="text-black  whitespace-nowrap">
            Paysela
          </span> */}
        </header>
        <div className="grid lg:grid-cols-12 h-screen gap-3">
          <div className="lg:col-span-6 flex items-center justify-center">
            <div
              style={{ display: "flex", flexDirection: "column", gap: "40px" }}
              className="login-wrapper w-full max-w-md mx-auto p-6"
            >
              <Link
                style={{ display: "flex", gap: "14px", marginBottom: "0px" }}
                className=" text-black-800 text-[18px] font-[Poppins] font-bold mb-8 xl:mb-16"
                to="/login"
              >
                {" "}
                <img src={backIcon} alt="" /> Back
              </Link>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <h5
                  style={{}}
                  className=" text-black-800 text-[32px] font-[700] font-[Poppins] "
                >
                  FORGOT PASSWORD?
                </h5>
                <text style={{ color: "#8F92A1" }}>
                  Add associated email to get verification code on
                </text>
              </div>
              {/* eslint-disable-next-line @typescript-eslint/no-misused-promises  */}
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "40px",
                }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <div>
                  <Input
                    isInvalid={Boolean(errors?.email)}
                    errorMessage={errors?.email?.message}
                    placeholder=""
                    label="Email"
                    type="email"
                    name="email"
                    register={register}
                    rules={{
                      required: "Email is required",
                    }}
                  />
                </div>

                <button
                  type="submit"
                  className="bg-cyan-800 hover:bg-cyan-300  hover:text-cyan-800 text-white text-x font-medium border border-cyan-800 rounded-lg w-full p-3.5 transition"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div
                      className="w-8 h-8 rounded-full animate-spin mx-auto
                      border-4 border-solid border-cyan-800 border-t-transparent"
                    ></div>
                  ) : (
                    "CONTINUE"
                  )}
                </button>
              </form>
            </div>
          </div>
          <div className="lg:col-span-6 relative lg:overflow-hidden">
            <div className="login-bg h-full flex items-center justify-center">
              <img
                style={{ height: "680px" }}
                className="max-w-xs lg:max-w-none object-contain"
                src={LockImg}
                alt="title"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
