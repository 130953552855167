import React, { useState, useEffect } from "react";
import Layout from "layout";

// import { type ListedUser } from "utils/types/user.type";
// import toast from "react-hot-toast";
// import { useSearchParams } from "react-router-dom";
// import avatar from "assets/images/avatar.png";

// import BitCoinIcon from "assets/icons/bitCoinIcon.svg";
import WithdrawalIcon from "assets/icons/withdrawalIcon.svg";
import XAFICON from "assets/icons/XAFICON.svg";

import Modal from "components/modal";
import { useSetFee, useGetFee } from "utils/api/user.api";
import toast from "react-hot-toast";
// import SwapIcon from "../../assets/images/swap.svg";
// import WithdrawIcon from "../../assets/images/withdrawal.svg";

export default function FiatFeeManagemnt() {
  //   const { data, isLoading } = useGetUserList();
  const [activeTab, setActiveTab] = useState("withdrawal");
  const { data } = useGetFee();
  console.log("🚀 ~ FiatFeeManagemnt ~ data:", data);

  const handleTabClick = (tab: any) => {
    setActiveTab(tab);
  };

  return (
    <Layout>
      <div className="dashboard-main" style={{ backgroundColor: " #FAFAFA" }}>
        <div
          className=" bg-cyan-800  rounded-lg"
          style={{
            position: "relative",
            height: "172px",
            borderRadius: "16px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <text
            style={{
              fontWeight: "500",
              fontSize: "32px",
              color: "white",
              padding: "24px",
            }}
          >
            Crypto Fee Management
          </text>
        </div>

        <div className="w-full flex  justify-center items-center  mx-auto pt-6">
          <div className="relative right-0 w-full ">
            <ul
              className="relative flex justify-start items-center   list-none  border-b-2 border-[#F2F5F8]"
              role="list"
            >
              <li
                className={` flex w-[300px] text-center justify-center ${
                  activeTab === "withdrawal"
                    ? "border-b-2 border-[#0291D1]"
                    : ""
                }`}
              >
                <a
                  className={` flex items-center justify-center  px-0 py-1 mb-0 transition-all ease-in-out border-0  cursor-pointer text-slate-700 ${
                    activeTab === "withdrawal"
                      ? "text-[#000000] font-[600] "
                      : ""
                  }`}
                  onClick={() => handleTabClick("withdrawal")}
                  role="tab"
                  aria-selected={activeTab === "withdrawal"}
                  aria-controls="withdrawal"
                >
                  <span className="ml-1">withdrawal</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div
          className={`${
            activeTab === "withdrawal"
              ? "block opacity-100"
              : "hidden opacity-0"
          } main-wrapper max-w-full py-6`}
          id="withdrawal"
          role="tabpanel"
        >
          <div className="table-wrapper ">
            <table
              className="w-full border border-[#F1F1F1] "
              style={{ borderCollapse: "collapse", borderSpacing: "0" }}
            >
              <thead className="bg-cyan-300 sticky top-0 z-10">
                <tr className="flex">
                  <th className="flex-1 py-3 px-5 text-left">Currency</th>
                  <th className="flex-1 py-3  text-left">Withdrawal Fee</th>

                  <th className="flex-1 py-3 text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item: any) => (
                  <TableRow key={item?.id} item={item} />
                ))}
                {data?.length === 0 && (
                  <tr>
                    <td>No Results Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
}

function TableRow(item: any) {
  const [withDrawalOpen, setWithDrawalOpen] = useState(false);
  const [withdrawFiatFee, setWithDrawFiatFee] = useState(item?.item?.value);
  console.log("🚀 ~ TableRow ~ withdrawFiatFee:", typeof withdrawFiatFee);

  const { mutate: setFee, isSuccess } = useSetFee();

  const handleWithdrawFiatFee = (e: any) => {
    setWithDrawFiatFee(e.target.value);
  };

  const UpdateWithdrawFiatFee = () => {
    const data = { feeName: "withdrawFiat", value: Number(withdrawFiatFee) };
    setFee(data);
    console.log("🚀 ~ UpdateWithdrawFiatFee ~ data:", data);
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success("Crypto Withdraw Fee updated successfully!");
      setWithDrawalOpen(false);
    }
  }, [isSuccess]);
  return (
    <>
      <tr className=" flex border-b border-gray-400 bg-[#FFFFFF]">
        <td className=" flex-1 py-2 px-5 rounded-l-lg flex items-center ">
          <h1 className="text-[14px] text-[#000000] font-[600]">
            {item?.item?.feeName}
          </h1>
        </td>
        <td className=" flex-1 py-2  text-left flex items-center">
          <p className="text-black-900 text-xs">{item?.item?.value}</p>
        </td>
        <td className="flex-1 py-2  rounded-r-lg  text-center ">
          <>
            <button
              className="text-gray-800 text-xs  bg-[#F1F1F1] border border-[#8F92A1] font-medium rounded-lg px-8 py-2.5 whitespace-nowrap"
              onClick={() => setWithDrawalOpen(true)}
            >
              UPDATE FEE
            </button>
          </>
        </td>
      </tr>
      <Modal isOpen={withDrawalOpen} onClose={() => setWithDrawalOpen(false)}>
        <div className="flex flex-col items-center overflow-auto gap-8 w-full h-full">
          <div
            className=" overflow-auto "
            style={{
              display: "flex",
              gap: "16px",

              alignItems: "center",
              width: "100%",
            }}
          >
            <img src={WithdrawalIcon} className="w-8 h-8 " alt="title" />
            <h1 className="text-[18px] font-[600] text-[#030319]">
              Fiat Withdrawal Fee{" "}
            </h1>
          </div>
          <div
            className=" overflow-auto "
            style={{
              display: "flex",
              gap: "16px",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div className="flex gap-2">
              <img src={XAFICON} className="w-7 h-7" alt="title" />
              <h1 className="text-[18px] font-[600] text-[#030319]">XAF</h1>
            </div>
            <p className="text-[18px] font-[600] text-[#0291D1]">5XAF</p>
          </div>
          <div className="mb-4 w-full  ">
            <label className="text-[#8F92A1] text-[14px] text-[400]">
              Withdrawal Fee
            </label>
            <div
              className={`flex items-center p-3 border border-[#E0E3E8]  rounded-lg mt-1`}
            >
              <input
                value={withdrawFiatFee}
                className="w-full text-[#8F92A1]    outline-none"
                placeholder="0"
                onChange={handleWithdrawFiatFee}
              />
              <div>
                <p className="text-[14px] text-[#8F92A1] font-normal">XAF</p>
              </div>
            </div>
          </div>
          <div className="w-full flex items-center justify-between gap-2">
            <button
              onClick={() => setWithDrawalOpen(false)}
              className="w-full text-[#000000] text-[16px] font-medium  bg-[#F8F8F8]  rounded-lg px-6 py-4"
            >
              CANCEL
            </button>
            <button
              onClick={UpdateWithdrawFiatFee}
              className="w-full bg-cyan-800 hover:text-cyan-800 hover:bg-cyan-300 text-white text-[16px] font-medium border  rounded-lg px-6 py-4 transition"
            >
              UPDATE
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
