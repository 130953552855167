import Layout from "layout";
import React, { useEffect, useState } from "react";
import { useGetUserList, useUpdateUser } from "utils/api/user.api";
import { type User } from "utils/types/user.type";
import toast from "react-hot-toast";

import { useSearchParams } from "react-router-dom";
import searchIconSrc from "assets/icons/searchIcon.svg";
import Modal from "components/modal";
import ImgPlaceholder from "assets/icons/imgPlaceholder.svg";
import DropIcon from "assets/icons/DropIcon.svg";

export default function AdminAccess() {
  const { data } = useGetUserList(true);
  const [searchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") ?? ""
  );

  return (
    <Layout>
      <div
        style={{ height: "100vh", backgroundColor: " #FAFAFA" }}
        className="dashboard-main  "
      >
        <div
          className=" bg-cyan-800 rounded-lg"
          style={{
            position: "relative",
            height: "172px",

            borderRadius: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "24px",
          }}
        >
          <text
            style={{
              fontWeight: "500",
              fontSize: "32px",
              color: "white",
            }}
          >
            Admin Management
          </text>
          <div className="relative ">
            <input
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search By Email"
              className="text-black-700 text-sm rounded-lg focus:outline-0 py-3.5 px-5 w-[390px]"
            />
            <img
              src={searchIconSrc}
              alt="Search"
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-grey-700 w-5 h-5"
            />
          </div>
        </div>
        <div className="main-wrapper max-w-full p-6">
          <div className="table-wrapper max-h-[28rem] overflow-auto">
            <table
              className="w-full"
              style={{ borderCollapse: "collapse", borderSpacing: "0" }}
            >
              <thead className="bg-cyan-300">
                <tr>
                  <th
                    style={{ width: "25%" }}
                    className="py-3 px-5 text-left text-[#030319] text-[16px] font-[600]"
                  >
                    Created
                  </th>
                  <th
                    style={{ width: "25%" }}
                    className="py-3 px-2 text-left  text-[#030319] text-[16px] font-[600]"
                  >
                    Name
                  </th>
                  <th
                    style={{ width: "25%" }}
                    className="py-3 px-2 text-left  text-[#030319] text-[16px] font-[600]"
                  >
                    Email
                  </th>
                  <th
                    style={{ width: "25%" }}
                    className="py-3 px-2 text-left  text-[#030319] text-[16px] font-[600]"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item) => <TableRow key={item?.id} user={item} />)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
}

function TableRow({ user }: { user: User }) {
  const [isEdit, setIsEdit] = useState(false);
  const [isActive, setIsActive] = useState(Boolean(user?.isActive));
  const [openModal, setOpenModal] = useState(false);

  const { mutate: updateUser, isLoading, isSuccess } = useUpdateUser();

  const handleUpdateUser = (value: string) => setIsActive(value === "active");

  useEffect(() => {
    if (isSuccess) {
      toast.success("User updated successfully!");
      setOpenModal(false);
    }
  }, [isSuccess]);

  return (
    <>
      <tr className="border-b border-gray-400">
        <td className="py-3 px-5">
          <div className="flex items-start">
            {/* {isEdit && (
              <img
                className="w-12 h-12 object-cover rounded-full mr-3"
                src={user?.image || avatar}
                alt="title"
              />
            )} */}
            <p className="text-[#8F92A1] text-[14px] font-[400] text-xs">
              {new Date(user?.createdAt)
                .toLocaleString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })
                .replace(/\//g, "-")}
            </p>
          </div>
        </td>
        <td className="py-3 px-2">
          <p className="text-[#8F92A1] text-[14px] font-[400]  text-xs">
            {user?.fullname}
          </p>
        </td>
        <td className="py-3 px-2">
          <p className="text-[#8F92A1] text-[14px] font-[400]  underline">
            {user?.email}
          </p>
        </td>
        <td className="py-3 px-2">
          <>
            <button
              className="text-gray-800 text-xs  bg-[#F1F1F1] border border-[#8F92A1]  font-medium rounded-lg px-8 py-2.5"
              onClick={() => {
                setIsEdit(!isEdit);
                setOpenModal(true);
              }}
            >
              CHANGE STATUS
            </button>
          </>
        </td>
      </tr>

      <Modal
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false);
          setIsEdit(!isEdit);
        }}
      >
        <div className="flex flex-col items-center gap-8  w-full h-full p-2">
          <div
            style={{
              display: "flex",

              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              border: "2px solid #0291D1",
              borderWidth: "0  0 2px 0",
              padding: "0px 0px 16px 0px",
            }}
          >
            <div className="flex gap-2 items-center">
              <img
                src={user?.image || ImgPlaceholder}
                className="w-20 h-20 object-cover rounded-full "
                alt="title"
              />
              <div style={{ flex: "1" }}>
                <p className="text-[#030319] text-[18px] font-[600]">
                  {user?.fullname}
                </p>
                <p className="text-[#8F92A1] text-[14px] font-[400]">
                  {user?.email}
                </p>
              </div>
            </div>
            <div
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "end",
                textAlign: "left",
              }}
            >
              <p className="text-[#030319] text-[16px] font-[500]">
                Date Created
              </p>
              <p className="text-[#8F92A1] text-[14px] font-[400]">
                {new Date(user?.createdAt)
                  .toLocaleString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })
                  .replace(/\//g, "-")}
              </p>
            </div>
          </div>
          <div className="relative max-w-md w-full">
            <select
              name="select-1"
              id="select-1"
              className="appearance-none max-w-md w-full border border-[#E0E3E8] px-3 py-3 rounded-lg focus:outline-0 mb-2"
              onChange={(e) => handleUpdateUser(e?.target?.value)}
              defaultValue={user?.isActive ? "active" : "de-active"}
              style={{
                WebkitAppearance: "none",
                MozAppearance: "none",
                appearance: "none",
                background: `url(${DropIcon}) no-repeat  right  1rem center / 1em`,
              }}
            >
              <option className="bg-black-300" value="active">
                Activate
              </option>
              <option className="bg-black-300" value="de-active">
                Deactivate
              </option>
            </select>
          </div>
          <div className="w-full flex items-center justify-between gap-2">
            <button
              onClick={() => setOpenModal(false)}
              className="text-[#000000] text-[16px] bg-[#F8F8F8] font-medium rounded-lg px-6 py-4 w-full"
            >
              CANCEL
            </button>
            <button
              onClick={() => updateUser({ userId: user?.id, isActive })}
              className="bg-cyan-800 hover:text-cyan-800 hover:bg-cyan-300 text-white w-full text-[16px] font-medium border border-cyan-800 rounded-lg px-6 py-4 transition"
            >
              {isLoading ? (
                <div className=" h-5 rounded-full animate-spin mx-auto border-2 border-solid w-full border-black-800 border-t-transparent"></div>
              ) : (
                "UPDATE"
              )}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
