/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "routes";
import toast, { Toaster } from "react-hot-toast";
import { messaging } from "utils/firebase";
import { onMessage } from "firebase/messaging";

function App() {
  useEffect(() => {
    // Handle incoming messages
    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      toast(
        `${payload.data?.title || "New Notification!"}: ${payload.data
          ?.message}`,
        {
          icon: "🔔",
          duration: 5000,
          style: { backgroundColor: "#333", color: "#fff" },
        }
      );
    });
  }, []);

  return (
    <BrowserRouter>
      <AppRoutes />
      <Toaster />
    </BrowserRouter>
  );
}

export default App;
