/* eslint-disable no-new */
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { sendTokenToBackend } from "utils/api/user.api"; // Assuming you have an API utility function for sending the token

const firebaseConfig = {
  apiKey: "AIzaSyAygJlAB60mW7VGNe2Xs3fRwvDj6tJ9S6I",
  authDomain: "paysela-98d49.firebaseapp.com",
  projectId: "paysela-98d49",
  storageBucket: "paysela-98d49.appspot.com",
  messagingSenderId: "28874130452",
  appId: "1:28874130452:web:4b278b199438ca9c1b9301",
  measurementId: "G-ZTVJRKHYVV",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// Register Service Worker for Firebase Messaging
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      console.log("Service Worker registered with scope:", registration.scope);
    })
    .catch((err) => {
      console.error("Service Worker registration failed:", err);
    });
}

// Function to Get FCM Token and Send to Backend
export const getTokenAndSendToBackend = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted.");

      // Get FCM token
      const token = await getToken(messaging, {
        vapidKey:
          "BItSiyjIIdnl52iM0C9A0l8zYZFqAG8LYJOqAtNrEJ3roj9jXnwQyWCxgVRUgbGHtv0riXfLuYhEPvtiKDl81N8",
      });

      if (token) {
        console.log("FCM Token:", token);

        // Send the token to the backend
        await sendTokenToBackend(token);
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    } else {
      console.log("Notification permission denied.");
    }
  } catch (error) {
    console.error("Error getting FCM token:", error);
  }
};

// Export the messaging instance for use in other parts of the app
export { messaging };
