import React, { useState, useEffect } from "react";
import Layout from "layout";
import { useGetCoins, useUpdateCryptoPrice } from "utils/api/user.api";

// import { type ListedUser } from "utils/types/user.type";
import toast from "react-hot-toast";
// import { useSearchParams } from "react-router-dom";
//   import avatar from "assets/images/avatar.png";

import Modal from "components/modal";

export default function CryptoRates() {
  const { data } = useGetCoins();
  console.log("🚀 ~ CryptoToFiatRates ~ data:", data);
  return (
    <Layout>
      <div className="dashboard-main " style={{ backgroundColor: " #FAFAFA" }}>
        <div
          className=" bg-cyan-800  rounded-lg"
          style={{
            position: "relative",
            height: "122px",
            borderRadius: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "24px",
          }}
        >
          <text
            style={{
              fontWeight: "500",
              fontSize: "32px",
              color: "white",
            }}
          >
            Crypto to Crypto Rates
          </text>
        </div>
        <div className="main-wrapper max-w-full py-6">
          <div className="table-wrapper max-h-[25rem] overflow-auto">
            <table
              className="w-full border border-[#F1F1F1] "
              style={{ borderCollapse: "collapse", borderSpacing: "0" }}
            >
              <thead className="bg-cyan-300 sticky top-0 z-10">
                <tr className="flex">
                  <th className="flex-1 py-3 px-5 text-left">Coins</th>
                  <th className="flex-1 py-3  text-left">
                    Percentage Difference
                  </th>
                  <th className="flex-1 py-3 text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item) => <TableRow key={item?.id} user={item} />)}
                {data?.length === 0 && (
                  <tr>
                    <td>No Results Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
}
function TableRow(user: any) {
  const [CoinOpen, setCoinOpen] = useState(false);

  const [percentageFee, setPercentageFee] = useState();
  const [percentageFeeError, setPercentageFeeError] = useState("");

  const { mutate: updateCryptoPrice, isSuccess } = useUpdateCryptoPrice();

  const handlePercentageFeeChange = (e: any) => {
    setPercentageFee(e.target.value);
    setPercentageFeeError("");
  };

  const updateCryptoRates = () => {
    if (!percentageFee) {
      return setPercentageFeeError("Crypto Fee Required");
    } else {
      const data = { coinId: user?.user?.id, price: Number(percentageFee) };
      updateCryptoPrice(data);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Crypto Fee updated successfully!");
      setCoinOpen(false);
    }
  }, [isSuccess]);

  return (
    <>
      <tr className=" flex border-b border-gray-400 bg-[#FFFFFF]">
        <td className=" flex-1 py-2 px-5 rounded-l-lg flex items-center">
          <div className="flex  text-left gap-2 ">
            <img className=" w-6 " src={user?.user?.icon} alt="title" />
            <h1 className="text-[14px] text-[#8F92A1] font-[400]">
              {user?.user?.name}
            </h1>
          </div>
        </td>
        <td className=" flex-1 py-2  text-left flex items-center">
          <p className="text-black-900 text-xs">{user?.user?.swapFee}</p>
        </td>

        <td className="flex-1 py-2  rounded-r-lg  text-center ">
          <>
            <button
              className="text-gray-800 text-xs  bg-[#F1F1F1] border border-[#8F92A1]  font-medium rounded-lg px-8 py-2.5 whitespace-nowrap"
              onClick={() => {
                setCoinOpen(true);
              }}
            >
              UPDATE RATE
            </button>
          </>
        </td>
      </tr>
      <Modal isOpen={CoinOpen} onClose={() => setCoinOpen(false)}>
        <div className="flex flex-col items-center overflow-auto gap-4 w-full h-full">
          <div
            className=" overflow-auto "
            style={{
              display: "flex",
              gap: "16px",

              alignItems: "center",
              width: "100%",
            }}
          >
            <h1 className="text-[18px] font-[600] text-[#030319]">
              Update Crypto Rate
            </h1>
          </div>

          <div className="bg-[#F8F8F8] p-6 rounded-lg w-full flex flex-col gap-4">
            <div className="w-full flex justify-center items-center gap-1">
              <img src={user?.user?.icon} alt="icon" className="w-8" />
              <h2 className="text-[18px] text-[#030319] font-[600]">
                {user?.user?.name}
              </h2>
            </div>
            <div className="flex justify-between pt-3">
              <h1 className="text-[18px] text-[#030319] font-[600]">
                Percentage Difference
              </h1>
              <h1 className="text-[16px] text-[#555555] font-[400]">
                {user?.user?.swapFee}%
              </h1>
            </div>
          </div>

          <div className=" w-full  mb-3">
            <label className="text-[#8F92A1] text-[14px] text-[400]">
              Enter New Percentage Difference
            </label>
            <div
              className={`flex items-center p-3 border border-[#E0E3E8]  rounded-lg mt-1`}
            >
              <input
                value={percentageFee}
                className="w-full text-[#8F92A1]    outline-none"
                placeholder="1%"
                onChange={handlePercentageFeeChange}
              />
            </div>
            {percentageFeeError && (
              <p className="text-red-500 font-normal text-[10px]">
                {percentageFeeError}
              </p>
            )}
          </div>

          <div className="w-full flex items-center justify-between gap-2">
            <button
              onClick={() => setCoinOpen(false)}
              className="w-full text-[#000000] text-[16px] font-medium  bg-[#F8F8F8]  rounded-lg px-6 py-4"
            >
              CANCEL
            </button>
            <button
              onClick={updateCryptoRates}
              className="w-full bg-cyan-800 hover:text-cyan-800 hover:bg-cyan-300 text-white text-[16px] font-medium border  rounded-lg px-6 py-4 transition"
            >
              UPDATE
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
