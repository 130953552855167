import React from "react";
import { useGetStats } from "utils/api/user.api";

export default function AppStats() {
  const { data } = useGetStats();
  return (
    <div className="grid grid-cols-12 gap-2 lg:gap-4  mb-4 md:mb-12  ">
      <div className="col-span-3 md:col-span-3 lg:col-span-3 h-36 ">
        <div className=" bg-white rounded-2xl transition p-2 lg:p-6 flex flex-col justify-center  shadow-md items-center ">
          <p className="text-black-800 text-[8px] md:text-[14px]  font-semibold mb-3 text-center  ">
            Total<br className="block xs:hidden"></br> Withdraw
          </p>
          <h4 className="text-cyan-800 text-sm lg:text-4xl font-bold">
            {data?.withdrawsFiat}
          </h4>
        </div>
      </div>
      <div className="col-span-3 md:col-span-3 lg:col-span-3 h-36">
        <div className=" bg-white rounded-2xl transition p-2 lg:p-6 flex flex-col justify-center shadow-md  items-center">
          <p className="text-black-800 text-[8px] md:text-[14px]  font-semibold mb-3 text-center">
            Total<br className="block xs:hidden"></br> Users
          </p>
          <h4 className="text-cyan-800 text-sm lg:text-4xl font-bold">
            {data?.totalUsers}
          </h4>
        </div>
      </div>
      <div className="col-span-3 md:col-span-3 lg:col-span-3 h-36">
        <div className=" bg-white rounded-2xl transitionp-2 p-2 lg:p-6 flex flex-col justify-center shadow-md  items-center">
          <p className="text-black-800 text-[8px] md:text-[14px] font-semibold mb-3 text-center">
            New <br className="block xs:hidden"></br> Users
          </p>
          <h4 className="text-cyan-800 text-sm lg:text-4xl font-bold">
            {data?.newUsers}
          </h4>
        </div>
      </div>
      <div className="col-span-3 md:col-span-3 lg:col-span-3 h-36">
        <div className=" bg-white rounded-2xl transition p-2 lg:p-6 flex flex-col justify-center shadow-md  items-center">
          <p className="text-black-800 text-[8px] md:text-[14px] font-semibold mb-3 text-center">
            Active <br className="block xs:hidden"></br> Users
          </p>
          <h4 className="text-cyan-800 text-sm lg:text-4xl font-bold">
            {data?.activeUsers}
          </h4>
        </div>
      </div>
    </div>
  );
}
