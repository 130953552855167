import React, { useState, useEffect } from "react";
import Layout from "layout";
import {
  // useGetUserList,
  // useGetUserWalletBalance,
  // useUpdateUser,
  useGetCoins,
  useUpdateCryptoWithdrawFee,
} from "utils/api/user.api";
// import { type ListedUser } from "utils/types/user.type";
import toast from "react-hot-toast";
// import { useSearchParams } from "react-router-dom";
// import avatar from "assets/images/avatar.png";

// import BitCoinIcon from "assets/icons/bitCoinIcon.svg";
import WithdrawalIcon from "assets/icons/withdrawalIcon.svg";

import Modal from "components/modal";
// import USDTIcon from "assets/icons/USDTIcon.svg"
// import { useSetFee, useGetFee } from "utils/api/user.api";
// import toast from "react-hot-toast";
// import SwapIcon from "../../assets/images/swap.svg";
// import WithdrawIcon from "../../assets/images/withdrawal.svg";

export default function RateSettings() {
  // const { data, isLoading } = useGetUserList();
  const { data } = useGetCoins();
  console.log("🚀 ~ RateSettings ~ data:", data);
  const [activeTab, setActiveTab] = useState("Withdrawal");

  const handleTabClick = (tab: any) => {
    setActiveTab(tab);
  };

  // const [state, setState] = useState<any>({
  //   feeName: "",
  //   feePercentage: 0,
  // });
  // const [withdrawState, setWithdrawState] = useState<any>({
  //   feeName: "",
  //   feePercentage: 0,
  // });
  // const { data } = useGetFee();
  // const { mutate: setFee, isLoading, isSuccess } = useSetFee();

  // const getFee = (name: string) => {
  //   const response = data?.find((item: any) => item.feeName === name);
  //   if (response) {
  //     return response?.feePercentage;
  //   } else {
  //     return 0;
  //   }
  // };
  // useEffect(() => {
  //   if (isSuccess) {
  //     toast.success("Price updated successfully!");
  //   }
  // }, [isSuccess]);

  return (
    <Layout>
      <div className="dashboard-main" style={{ backgroundColor: " #FAFAFA" }}>
        <div
          className=" bg-cyan-800  rounded-lg"
          style={{
            position: "relative",
            height: "172px",
            borderRadius: "16px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <text
            style={{
              fontWeight: "500",
              fontSize: "32px",
              color: "white",
              padding: "24px",
            }}
          >
            Crypto Fee Management
          </text>
        </div>

        <div className="w-full flex  justify-center items-center  mx-auto pt-6">
          <div className="relative right-0 w-full ">
            <ul
              className="relative flex justify-start items-center   list-none  border-b-2 border-[#F2F5F8]"
              role="list"
            >
              <li
                className={` flex w-[300px] text-center justify-center ${
                  activeTab === "Withdrawal"
                    ? "border-b-2 border-[#0291D1]"
                    : ""
                }`}
              >
                <a
                  className={` flex items-center justify-center  px-0 py-1 mb-0 transition-all ease-in-out border-0  cursor-pointer text-slate-700 ${
                    activeTab === "Withdrawal"
                      ? "text-[#000000] font-[600] "
                      : ""
                  }`}
                  onClick={() => handleTabClick("Withdrawal")}
                  role="tab"
                  aria-selected={activeTab === "Withdrawal"}
                  aria-controls="Withdrawal"
                >
                  <span className="ml-1">Withdrawal</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div
          className={`${
            activeTab === "Withdrawal"
              ? "block opacity-100"
              : "hidden opacity-0"
          } main-wrapper max-w-full py-6`}
          id="withdrawal"
          role="tabpanel"
        >
          <div className="dashboard-sidebar table-wrapper  max-h-[24rem] overflow-auto">
            <table
              className="w-full border border-[#F1F1F1] "
              style={{ borderCollapse: "collapse", borderSpacing: "0" }}
            >
              <thead className="bg-cyan-300 sticky top-0 z-10">
                <tr className="flex px-4">
                  <th className="flex-1 py-3  text-left">Token</th>
                  <th className="flex-1 py-3  text-left">Withdrawal Fee</th>
                  <th className="flex-1 py-3 text-center">Action</th>
                </tr>
              </thead>
              <tbody className="  ">
                {data?.map((item) => <TableRow key={item?.id} item={item} />)}
                {data?.length === 0 && (
                  <tr>
                    <td>No Results Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
}

function TableRow(item: any) {
  console.log("🚀 ~ TableRow ~ item:", item);
  const [withDrawalOpen, setWithDrawalOpen] = useState(false);
  const [withdrawFee, setWithDrawFee] = useState(item?.item?.withdrawFee);

  const { mutate: UpdateCryptoWithdrawFee, isSuccess } =
    useUpdateCryptoWithdrawFee();

  const handleWithdrawFee = (e: any) => {
    setWithDrawFee(e.target.value);
  };

  const UpdateWithdrawFee = () => {
    const data = { coinId: item?.item?.id, price: withdrawFee };
    UpdateCryptoWithdrawFee(data);
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success("Crypto Withdraw Fee updated successfully!");
      setWithDrawalOpen(false);
    }
  }, [isSuccess]);
  return (
    <>
      {/* withdrawal table rows and modal */}
      <tr className=" flex  border-b border-gray-400 bg-[#FFFFFF]">
        <td className=" flex-1 py-2 px-1.5 rounded-l-lg ">
          <div className="flex   items-center gap-2 px-2">
            <img src={item?.item?.icon} alt="title" className="w-5" />
            <h1 className="text-[16px] text-[#000000] font-[400]">
              {item?.item?.symbol}
            </h1>
          </div>
        </td>
        <td className=" flex-1 py-2 flex text-left items-center">
          <p className="text-[#8F92A1] text-xs font-[400]">
            {item?.item?.withdrawFee}
          </p>
        </td>
        <td className="flex-1 text-center rounded-r-lg   py-2">
          <>
            <button
              className="text-gray-800 text-xs  bg-[#F1F1F1] border border-[#8F92A1] font-medium rounded-lg px-8 py-2.5 whitespace-nowrap"
              onClick={() => setWithDrawalOpen(true)}
            >
              UPDATE FEE
            </button>
          </>
        </td>
      </tr>
      <Modal isOpen={withDrawalOpen} onClose={() => setWithDrawalOpen(false)}>
        <div className="flex flex-col items-center overflow-auto gap-8 w-full h-full">
          <div
            className=" overflow-auto "
            style={{
              display: "flex",
              gap: "16px",

              alignItems: "center",
              width: "100%",
            }}
          >
            <img src={WithdrawalIcon} className="w-8 h-8 " alt="title" />
            <h1 className="text-[18px] font-[600] text-[#030319]">
              Crypto Withdrawal Fee{" "}
            </h1>
          </div>
          <div
            className=" overflow-auto "
            style={{
              display: "flex",
              gap: "16px",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div className="flex gap-2">
              <img src={item?.item?.icon} className="w-7 h-7" alt="title" />
              <h1 className="text-[18px] font-[600] text-[#030319]">
                {item?.item?.symbol}
              </h1>
            </div>
            <p className="text-[18px] font-[600] text-[#0291D1]">
              {item?.item?.withdrawFee}
              {item?.item?.networkId?.symbol}
            </p>
          </div>
          <div className="mb-4 w-full  ">
            <label className="text-[#8F92A1] text-[14px] text-[400]">
              Withdrawal Fee
            </label>
            <div
              className={`flex items-center p-3 border border-[#E0E3E8]  rounded-lg mt-1`}
            >
              <input
                value={withdrawFee}
                className="w-full text-[#8F92A1]    outline-none"
                placeholder="0"
                onChange={handleWithdrawFee}
              />
              <div>
                <p className="text-[14px] text-[#8F92A1] font-normal">
                  {item?.item?.symbol}
                </p>
              </div>
            </div>
          </div>
          <div className="w-full flex items-center justify-between gap-2">
            <button
              onClick={() => setWithDrawalOpen(false)}
              className="w-full text-[#000000] text-[16px] font-medium  bg-[#F8F8F8]  rounded-lg px-6 py-4"
            >
              CANCEL
            </button>
            <button
              onClick={UpdateWithdrawFee}
              className="w-full bg-cyan-800 hover:text-cyan-800 hover:bg-cyan-300 text-white text-[16px] font-medium border  rounded-lg px-6 py-4 transition"
            >
              UPDATE
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
